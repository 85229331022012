<template>
  <div class="wrapper-slider">
    <Flicking :plugins="plugins"
      :options="{
        duration: 2000,
        animationDuration: 500,
        align: 'prev',
        noPanelStyleOverride: false,
        circular: true,
        circularFallback: 'bound',
        align: 'center',
      }"
    >
      <template v-for="bData in bannerData" :key="bData.name">
        <div class="intermezzo">
          <div class="container">
            <div class="wrapper-inter">
              <div class="box">
                <img :src="media + bData.image" :alt="bData.name" />
              </div>
              <div class="box">
                <h3>{{ bData.name }}</h3>
                <div v-html="bData.description"></div>
              </div>
              <div class="box">
                <a
                  :href="bData.link"
                  class="btn-ads"
                  :style="{ backgroundColor: bData.color }"
                  >Getting Started!</a
                >
              </div>
            </div>
          </div>
        </div>
      </template>
    </Flicking>
  </div>
</template>

<script>
import { getContent } from "@/api/rimba";
import Flicking from "@egjs/vue3-flicking";
// import { AutoPlay, Pagination } from "@egjs/flicking-plugins";
import { AutoPlay } from "@egjs/flicking-plugins";
import "@egjs/vue3-flicking/dist/flicking.css";
import "@egjs/flicking-plugins/dist/flicking-plugins.css";
import "@egjs/vue3-flicking/dist/flicking-inline.css";
const plugins = [new AutoPlay({ duration: 2000, direction: "NEXT", stopOnHover: false })];

export default {
  name: "IntermezzoComp",
  components: {
    Flicking,
  },
  props: {
    msg: String,
  },
  data() {
    return {
      plugins,
      bannerData: [],
      media: process.env.VUE_APP_MEDIA_URL,
    };
  },
  mounted() {
    this.refreshBanner();
  },
  methods: {
    async refreshBanner() {
      const getResponse = await getContent("product-promotion");
      if (getResponse.status == 200) {
        this.bannerData = getResponse.data.data;
        console.log(this.bannerData);
      } else {
        console.log(getResponse);
      }
    },
  },
};
</script>

<style>
.intermezzo {
  width: 100% !important;
  height: 50vh;
  background: url("@/assets/images/inter-bg.png"), #f6c23e;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2;
}

.wrapper-inter {
  display: grid;
  width: 100%;
  column-gap: 20px;
  grid-template-columns: repeat(8, 1fr);
  padding: 20px;
}
.wrapper-inter .box:nth-child(1) {
  grid-column: span 3;
  width: 100%;
}
.wrapper-inter .box:nth-child(1) img {
  width: 100%;
}
.wrapper-inter .box:nth-child(2) {
  grid-column: span 3;
  text-align: left;
  margin: auto 0;
}
.wrapper-inter .box:nth-child(2) h3 {
  font-weight: 800;
  font-size: 36px;
  line-height: 130%;
  color: #fff;
  letter-spacing: 0.005em;
}
.wrapper-inter .box:nth-child(2) p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 208%;
  letter-spacing: 0.01em;
  color: #ffffff;
}
.wrapper-inter .box:nth-child(3) {
  grid-column: span 2;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrapper-inter .box:nth-child(3) a {
  background: #ffffff;
  border: 1px solid transparent;
  box-shadow: 10px 10px 40px rgba(164, 164, 164, 0.25);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 19px;
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #13443d;
  text-decoration: none;
  width: 50%;
  transition: all 0.5s ease;
}
.wrapper-inter .box:nth-child(3) a:hover {
  background: transparent !important;
  border: 1px solid #fff;
  transition: all 0.5s ease;
  color: #000;
}
@media (max-width: 1440px) {
  .wrapper-inter .box:nth-child(3) a {
    padding: 18px 20px;
    width: 60%;
  }
}
@media (max-width: 1285px) {
  .wrapper-inter .box:nth-child(3) a {
    width: 65%;
  }
}
@media (max-width: 1024px) {
  .wrapper-inter .box:nth-child(3) a {
    width: 80%;
  }
  .wrapper-inter .box:nth-child(2) h3 {
    font-size: 28px;
  }
}

@media (max-width: 992px) {
  .intermezzo {
    height: auto;
  }
  .wrapper-inter .box:nth-child(3) a {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .wrapper-inter .box:nth-child(1) {
    grid-column: span 8;
  }
  .wrapper-inter .box:nth-child(2) {
    grid-column: span 8;
    text-align: center;
    margin-bottom: 20px;
  }
  .wrapper-inter .box:nth-child(3) {
    grid-column: span 8;
  }
  .wrapper-inter .box:nth-child(1) img {
    width: 60%;
  }
  .intermezzo {
    height: auto;
    padding: 30px 0px 50px 0px;
  }
  .wrapper-inter .box:nth-child(3) a {
    width: 45%;
  }
}
@media (max-width: 395px) {
  .wrapper-inter .box:nth-child(3) a {
    width: 60%;
  }
}
@media (max-width: 285px) {
  .wrapper-inter .box:nth-child(1) img {
    width: 100%;
  }
  .wrapper-inter .box:nth-child(3) a {
    width: 80%;
  }
}
</style>
