import { createRouter, createWebHistory, VueRouter } from "vue-router";
// import HomeView from "../views/HomeView.vue";
// import AboutView from "../views/AboutView.vue";
// import ServiceView from "../views/ServiceView.vue";
// import PortfolioView from "../views/PortfolioView.vue";
// import EventView from "../views/EventView.vue";
// import GalleryView from "../views/GalleryView.vue";
// import CareerView from "../views/CareerView.vue";
// import CareerDetailView from "../views/CareerDetailView.vue";
// import BlogView from "../views/BlogView.vue";
// import BlogDetailView from "../views/BlogDetailView.vue";
// import ContactView from "../views/ContactView.vue";
// import PortfolioDetailView from "../views/PortfolioDetailView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import('../views/HomeView.vue'),
  },
  {
    path: "/about",
    name: "about",
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: "/service",
    name: "service",
    component: () => import('../views/ServiceView.vue'),
  },
  {
    path: "/portfolio",
    name: "portfolio",
    component: () => import('../views/PortfolioView.vue'),
  },
  {
    path: "/portfolio/:slug",
    name: "portfolio-detail",
    component: () => import('../views/PortfolioDetailView.vue'),
    props: true,
  },
  {
    path: "/events",
    name: "event",
    component: () => import('../views/EventView.vue'),
  },
  {
    path: "/galleries",
    name: "gallery",
    component: () => import('../views/GalleryView.vue'),
  },
  {
    path: "/career",
    name: "career",
    component: () => import('../views/CareerView.vue'),
  },
  {
    path: "/career/:slug",
    name: "career-detail",
    component: () => import('../views/CareerDetailView.vue'),
    props: {
      default: true,
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: () => import('../views/BlogView.vue'),
  },
  {
    path: "/blog/:slug",
    name: "blog-detail",
    component: () => import('../views/BlogDetailView.vue'),
    props: true,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import('../views/ContactView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

export default router;
