<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content}` : `Rimba House`
    }}</template>
  </metainfo>
  <template v-if="isLoading">
    <PreloaderComp :isLoading="isLoading" />
  </template>
  <template v-else>
    <main>
      <router-view />
    </main>
  </template>
  <!-- <router-view /> -->
</template>

<script>
import HomeView from "./views/HomeView.vue";
import AboutView from "./views/AboutView.vue";
import PreloaderComp from "@/components/template/PreloaderComp.vue";
import { useMeta } from "vue-meta";

export default {
  name: "App",
  components: {
    HomeView,
    AboutView,
    PreloaderComp,
  },
  data() {
    return { 
      isLoading: true
    };
  },
  mounted() {
    const statusVisited = localStorage.getItem('visitedRimba');
    if (statusVisited !== 'visited') {
      setTimeout(() => {
        this.isLoading = false;
        localStorage.setItem('visitedRimba', 'visited');
      }, 9500);
    }else {
      this.isLoading = false;
      localStorage.setItem('visitedRimba', 'visited');
    }
  },
  setup() {
    useMeta({
      title: "Rimba House",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
